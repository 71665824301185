<template>
  <div class="surface-section w-full md:w-6 p-6 md:p-8">
    <div class="mb-5">
      <div class="text-900 text-3xl font-medium mb-3">Inscrivez-vous pour poursuivre</div>
    </div>
    <div>
      <form @submit.prevent="handleSubmit()" class="p-fluid">
        <label for="firstname" class="block text-900 font-medium mb-2">Prénom *</label>
        <InputText id="firstname" :value="firstname" type="text" disabled class="w-full mb-3 p-3" />

        <label for="lastname" class="block text-900 font-medium mb-2">Nom *</label>
        <InputText id="lastname" :value="lastname" type="text" disabled class="w-full mb-3 p-3" />

        <label for="email" class="block text-900 font-medium mb-2">Email *</label>
        <InputText id="email" :value="email" type="text" disabled class="w-full mb-3 p-3" />

        <label for="password" class="block text-900 font-medium mb-2">Mot de passe *</label>
        <InputText id="password" v-model="password" type="password" class="w-full mb-3 p-3" />

        <Button label="Inscription" type="submit" icon="pi pi-user" class="w-full p-3 mt-6"></Button>
      </form>
    </div>
  </div>
  <div class="hidden md:block w-6 bg-no-repeat bg-cover" :style="{ backgroundImage: 'url(' + require('@/assets/images/signin/signin.jpg') + ')' }" />
</template>

<script>

import {mapState} from "vuex";
import Alert from "@/utils/Alert";

export default {
  data () {
    return {
      firstname: '',
      lastname: '',
      email: '',
      password: '',
      submitted: false
    }
  },
  computed: {
    ...mapState({
      independent: state => state.independent.independent,
    })
  },
  async created () {
    if (!this.$route.params.token || !this.$route.params.idInde) {
      this.$router.push({ name: 'Login' });
    }
    this.$store.dispatch('independent/getOneIndependentForRegister', this.$route.params.idInde).then(() => {
      this.firstname = this.independent.first_name
      this.lastname = this.independent.last_name
      this.email = this.independent.email
    }).catch(() => {
      this.$router.push({ name: 'Login' });
    })
  },
  methods: {
    handleSubmit () {
      this.submitted = true

      // @todo spariaud replace by validation
      if (!this.firstname || !this.lastname || !this.email || !this.password) {
        return;
      }

      const payload = {
        first_name: this.firstname,
        last_name: this.lastname,
        email: this.email,
        password: this.password
      }

      this.$store.dispatch('auth/register', { sharedId: this.$route.params.token, independentId: this.$route.params.idInde, payload: payload }).then(() => {
        const loginPayload = {
          email: this.email,
          password: this.password
        }
        this.$store.dispatch('auth/login', loginPayload)
            .catch((e) => {
              Alert.errorMessage(this, e?.response?.data[0]?.extensions.code ?? 'defaultLoginError')
            })
            .finally(() => {
              this.$router.push('/home')
            })
      }).catch(() => {
        this.resetForm()
      })
    },
    resetForm () {
      this.firstname = ''
      this.lastname = ''
      this.email = ''
      this.password = ''
      this.submitted = false
    }
  }
}
</script>

<style lang="less" scoped>
</style>
